<template>
  <div class="container">
      <el-menu
          :default-active="activeIndex"
          :router="true"
          class="el-menu-vertical-demo"
          background-color="#545c64"
          text-color="#fff"
          :unique-opened="true"
          active-text-color="#ffd04b" style="height: 100%">
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-user-solid"></i>
			<!-- 3.26修改 -->
            <span>小程序用户管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="User">用户列表</el-menu-item>
            <el-menu-item index="UserCharts">用户统计图</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-s-finance"></i>
            <span>充值记录</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="PayRecord">充值列表</el-menu-item>
            <el-menu-item index="PayRecordCharts">充值统计图</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-s-unfold"></i>
            <span>设备管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="QrCode">设备列表</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="4">
          <template slot="title">
            <i class="el-icon-s-order"></i>
            <span>App管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="AppList">App列表</el-menu-item>
			<el-menu-item index="AppList1">App更新列表</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
		
		<!-- 3.26新增 -->
		<el-submenu index="5">
		  <template slot="title">
		    <i class="el-icon-s-order"></i>
		    <span>App用户管理</span>
		  </template>
		  <el-menu-item-group>
		    <el-menu-item index="AppUser">App用户列表</el-menu-item>
		  </el-menu-item-group>
		  <el-menu-item-group>
		    <el-menu-item index="App_sc">App用户统计列表</el-menu-item>
		  </el-menu-item-group>
		</el-submenu>

      </el-menu>

  </div>
</template>

<script>
export default {
  name: "Aside",
  data(){
	  return{
		  activeIndex: '/',
	  }
  },
  // watch:{
	 //  $route(){
		//   this.setCurrentRoute();
	 //  }
  // },
  // methods:{
	 //  Index(){
		//   this.activeIndex = this.$route.name;
	 //  }
	 //  // setCurrentRoute() {
	 //  //       this.activeIndex= this.$route.path; // 通过他就可以监听到当前路由状态并激活当前菜单
	 //  //     }
  // },
  created() {
  	// this.Index();
	 this.activeIndex = this.$route.name;
  }
  // created() {
  // 	this.nowyemian = this.$route.path;
  // }
}
</script>

<style scoped>
  .container{

    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    width: 240px;
  }
</style>

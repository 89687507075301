<template>
 <div>
   <div class="container1" style="min-height: 100vh">
     <div class="head">
       <div class="left">
         <div class="title">芝华仕管理后台</div>
         <div v-if="false" class="message"><i class="el-icon-message-solid"></i></div>
       </div>
       <div class="right">
         <img src="../../static/touxiang.png" />
         <el-dropdown @command="logout">
          <span style="color: white;cursor: pointer;" class="el-dropdown-link">
            {{userData.name}}<i style="color: white;" class="el-icon-arrow-down el-icon--right"></i>
          </span>
           <el-dropdown-menu slot="dropdown">
             <el-dropdown-item command="a">退出</el-dropdown-item>
           </el-dropdown-menu>
         </el-dropdown>
       </div>
     </div>
     <div class="body">
       <div class="aside">
         <Aside></Aside>
       </div>
       <div class="main"><router-view></router-view></div>
     </div>
   </div>
 </div>
</template>

<script>
import Aside from "@/components/Aside";
export default {
  name: "home",
  components: {
    Aside
  },
  data() {
    return {
      test: '',
      userData: {
        name: ''
      }
    }
  },
  computed: {

  },
  mounted() {
    setTimeout(() => {
      this.$ajax('getInfo', 'get').then(res => {
        console.log(res,'用户信息')
        // 保存用户信息到vuex
        this.userData = res.data
        // 跳转首页
		console.log(sessionStorage.getItem("User"),'用户');
		// if(res.data.name == '管理员') {
		// 	sessionStorage.setItem("User", 1);
		// 	console.log(sessionStorage.getItem("User"),'用户');
		// }else{
		// 	sessionStorage.setItem("User", 2);
		// 	console.log(sessionStorage.getItem("User"),'用户');
		// }
      })
    },100)

  },
  methods: {
    logout() {
      console.log('ss')
      this.$ajax('logout', 'post')
      sessionStorage.clear()
      this.$router.push('/login')
    },
  },
}
</script>

<style scoped lang="stylus">

  .head
    height 80px
    background-color #2d3a4b
    padding 0 20px
    display flex
    justify-content space-between
    .right
      width 10%
      color white
      display flex
      align-items center
      padding-left 20px
      &::before
        content ''
      img
        border-radius 100px
        margin-right 10px
      .name
        margin-right 5px
    .left
      display flex
      justify-content space-between
      width 90%
      .title
        font-size 24px
        color white
        line-height 80px
      .message
        line-height 80px
        color white
  .body

    .aside
      position absolute
      left 0
      top 80px
      bottom 0
      width 240px
      //right 200px
      //background-color red
    .main
      position absolute
      top 80px
      bottom 0
      left 240px
      right 0
      //background-color antiquewhite
      padding 30px
      overflow: hidden
</style>
